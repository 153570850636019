<template>
  <div v-if="dataCalculated">
    <div class="only-print-show print-page header_page">
      <!-- <div class="" style="height:900px"> -->
      <img class="serdex_logo" :src="serdexLogoUrl" alt="">
      <div class="header_content">
        <img class="projet_logo" :src="projetLogoUrl" alt="">
        <h2>Rapport de suivi:</h2>
        <h3>En date du: {{ (this.$moment()).format("YYYY-MM-DD") }}</h3>
      </div>
      <div class="header_footer">Serdex International {{ (this.$moment()).format("YYYY") }}. Tous droits réservés</div>
    </div>
    <RapportExterne v-if="externe" :projet_id="projet_id" :sections="sections" :commentaires="commentaires"
      :salons_headers="salons_headers" :objectifs="objectifs" :gaugeChartDataEchanciers="gaugeChartDataEchanciers"
      :barChartDataEchanciers="barChartDataEchanciers">
    </RapportExterne>
    <RapportInterne v-if="interne" :projet_id="projet_id" :sections="sections" :commentaires="commentaires"
      :salons_headers="salons_headers" :objectifs="objectifs" :barChartDataEchanciers="barChartDataEchanciers"
      :barChartDataAvancement="barChartDataAvancement" :gaugeChartDataEchanciers="gaugeChartDataEchanciers"
      :gaugeChartDataAvancementActivites="gaugeChartDataAvancementActivites">
    </RapportInterne>
  </div>
</template>

<script>
//eslint-disable-next-line no-unused-vars
import { serverUrl } from "../../store/modules/urls";
import { mapActions } from "vuex";
import RapportInterne from "./RapportInterne.vue";
import RapportExterne from "./RapportExterne.vue";
export default {
  name: "RapportViewComponent",
  components: {
    RapportInterne,
    RapportExterne,
  },
  props: ["projet_id", "type_rapport"],
  data: () => ({
    serdexLogoUrl: "",
    projetLogoUrl: "",
    projet: null,
    objectifs: null,
    activites: null,
    taches: null,
    commentaires: null,
    barChartDataEchanciers: {},
    barChartDataAvancement: {},
    gaugeChartDataEchanciers: {},
    gaugeChartDataAvancementActivites: {},
    dataCalculated: false,
    interne: false,
    externe: false,
    sections: ["Ensemble du projet", "Salons", "Donneées par objectif"],
    avancementItems: [
      { label: "Non débuté", color: "#FF0000" },
      { label: "En continu", color: "#C5E0B4" },
      { label: "En cours", color: "#FFC000" },
      { label: "Terminé", color: "#548235" },
    ],
    echeanciersItems: [
      { label: "Respecté", color: "#C5E0B4" },
      { label: "Devancé", color: "#548235" },
      { label: "Retard", color: "#FF0000" },
    ],
    salons_headers: [
      {
        text: "", snackbar: false,
        value: "nom_salon",
        show: true,
      },
      {
        text: "Type de participation",
        value: "type_participation",
        show: true,
      },
      { text: "Réservation effectuée", value: "reservation", show: true },
      { text: "Avancement", value: "avancement", show: true },
      { text: "RESPONSABLE", value: "responsable", show: false },
      { text: "AVEC L'APPUI DE", value: "appui", show: false },
      { text: "Commentaires", value: "commentaires", show: false },
      { text: "Controles", value: "actions", sortable: false, show: false },
    ],
  }),
  computed: {
    dataFetched() {
      return this.projet != null && this.commentaires != null && this.objectifs != null && this.activites != null && this.taches != null
    },
    activitesByObjectif() {
      if (this.dataFetched) {
        return this.activites?.reduce((accumulator, activite) => {
          let objectif_id = activite.objectif
          if (Object.keys(accumulator).includes(objectif_id.toString()))
            accumulator[objectif_id].push(activite)
          else
            accumulator[objectif_id] = [activite]
          return accumulator
        }, {})
      }
      return {}
    },
    tachesByActivite() {
      if (this.dataFetched) {
        return this.taches?.reduce((accumulator, tache) => {
          let activite_id = tache.activite
          if (Object.keys(accumulator).includes(activite_id.toString()))
            accumulator[activite_id].push(tache)
          else
            accumulator[activite_id] = [tache]
          return accumulator
        }, {})
      }
      return {}
    }
  },
  methods: {
    ...mapActions(["fetchProjet", "fetchObjectifsProjet", "fetchActivitesProjet", "fetchTachesProjet", "fetchCommentaires"]),
    setVizualisations() {

      this.barChartDataEchanciers = this.getBarChartDataEchanciers()
      this.barChartDataAvancement = this.getBarChartDataAvancement()
      this.gaugeChartDataEchanciers = this.getGaugeChartDataEchanciers()
      this.gaugeChartDataAvancementActivites = this.getGaugeChartDataAvancementActivites()

      this.serdexLogoUrl = `${serverUrl}projets/logo`;
      this.projetLogoUrl = `${serverUrl}projet/${this.projet.nom_projet}/logo`;

      this.dataCalculated = true;
    },

    getGaugeChartDataEchanciers() {
      const title = "Echéanciers:Ensemble du projet"
      const colors = []

      var proportionsEcheancier = this.taches.reduce((accumulator, tache) => {
        var etat_echeancier = tache.etat_echeancier;
        if (!Object.keys(accumulator).includes(etat_echeancier)) {
          accumulator[etat_echeancier] = 0
        }

        if (!Object.keys(accumulator).includes("total")) {
          accumulator["total"] = 0
        }

        accumulator[etat_echeancier] += 1
        accumulator["total"] += 1
        return accumulator

      }, {})

      const seriesData = this.echeanciersItems.reduce((acc, item) => {
        const label = item.label

        let value = 0
        if (Object.keys(proportionsEcheancier).includes(label)) {
          const total = proportionsEcheancier["total"]
          value = proportionsEcheancier[label]
          value = (value / total) * 100
        }

        if (value != 0) {
          acc.push({ name: label, value: value })
          colors.push(item.color)
        }
        return acc
      }, [])

      const series = {
        name: "écheanciers projet",
        type: 'pie',
        radius: ['40%', '70%'],
        label: {
          show: true,
          fontSize: 14,
          position: "inner",
          formatter: (params) => Math.round(params.value) + '%'
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        data: seriesData
      }

      return { title, series, colors }
    },

    getGaugeChartDataAvancementActivites() {
      const title = "Avancement des activités:Ensemble du projet"
      const colors = []

      var proprtionsAvancement = this.activites.reduce((accumulator, activite) => {
        var avancement = activite.avancement;
        if (!Object.keys(accumulator).includes(avancement)) {
          accumulator[avancement] = 0
        }

        if (!Object.keys(accumulator).includes("total")) {
          accumulator["total"] = 0
        }

        accumulator[avancement] += 1
        accumulator["total"] += 1
        return accumulator

      }, {})

      const seriesData = this.avancementItems.reduce((acc, item) => {
        const label = item.label

        let value = 0
        if (Object.keys(proprtionsAvancement).includes(label)) {
          const total = proprtionsAvancement["total"]
          value = proprtionsAvancement[label]
          value = (value / total) * 100
        }

        if (value != 0) {
          acc.push({ name: label, value: value })
          colors.push(item.color)
        }
        return acc
      }, [])

      const series = {
        name: "avancement projet",
        type: 'pie',
        radius: ['40%', '70%'],
        label: {
          show: true,
          fontSize: 14,
          position: "inner",
          formatter: (params) => Math.round(params.value) + '%'
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        data: seriesData
      }

      return { title, series, colors }
    },

    getBarChartDataEchanciers() {
      const proportionsTachesByActivites = this.taches.reduce((acc, tache) => {
        const activite_id = tache.activite
        const etat_echeancier = tache.etat_echeancier;

        if (!Object.keys(acc).includes(activite_id.toString())) {
          acc[activite_id] = { total: 0 }
        }

        if (!Object.keys(acc[activite_id]).includes(etat_echeancier)) {
          acc[activite_id][etat_echeancier] = 0
        }

        acc[activite_id][etat_echeancier] += 1
        acc[activite_id]["total"] += 1

        return acc
      }, {})

      const plotDataByObjectif = this.objectifs.reduce((acc, objectif, index) => {
        const activites = this.activitesByObjectif[objectif.id];
        const categories = []
        const colors = []
        const title = `Echéanciers ${objectif.nom_objectif}`

        const series = this.echeanciersItems.reduce((acc, item) => {
          const label = item.label

          const serie = {
            name: label,
            type: 'bar',
            stack: 'total',
            label: {
              show: true,
              formatter: (params) => Math.round(params.value) + '%'
            },
            emphasis: {
              focus: 'series'
            },
            data: []
          }

          acc[label] = serie
          colors.push(item.color)
          return acc
        }, {})

        if (activites != undefined) {
          activites.map((activite) => {
            const activite_id = activite.id
            const activite_nom = activite.nom_activite
            const propsTaches = proportionsTachesByActivites[activite_id]

            if (propsTaches != undefined) {

              Object.keys(series).map((k) => {
                const serie = series[k]
                const label = serie.name
                let value = 0
                if (Object.keys(propsTaches).includes(label)) {
                  const total = propsTaches["total"]
                  value = propsTaches[label]
                  value = (value / total) * 100
                }
                serie.data.push(value)
              })

              categories.push(activite_nom)
            }
          })
        }

        acc[index] = { title, series: Object.values(series), categories, colors }
        return acc
      }, {})

      return plotDataByObjectif

    },

    getBarChartDataAvancement() {
      const proportionsTachesByActivites = this.taches.reduce((acc, tache) => {
        const activite_id = tache.activite
        const avancement = tache.avancement;

        if (!Object.keys(acc).includes(activite_id.toString())) {
          acc[activite_id] = { total: 0 }
        }

        if (!Object.keys(acc[activite_id]).includes(avancement)) {
          acc[activite_id][avancement] = 0
        }

        acc[activite_id][avancement] += 1
        acc[activite_id]["total"] += 1

        return acc
      }, {})

      const plotDataByObjectif = this.objectifs.reduce((acc, objectif, index) => {
        const activites = this.activitesByObjectif[objectif.id];
        const categories = []
        const colors = []
        const title = `Avancement ${objectif.nom_objectif}`

        const series = this.avancementItems.reduce((acc, item) => {
          const label = item.label

          const serie = {
            name: label,
            type: 'bar',
            stack: 'total',
            label: {
              show: true,
              formatter: (params) => Math.round(params.value) + '%'
            },
            emphasis: {
              focus: 'series'
            },
            data: []
          }

          acc[label] = serie
          colors.push(item.color)
          return acc
        }, {})

        if (activites != undefined) {
          activites.map((activite) => {
            const activite_id = activite.id
            const activite_nom = activite.nom_activite
            const propsTaches = proportionsTachesByActivites[activite_id]

            if (propsTaches != undefined) {

              Object.keys(series).map((k) => {
                const serie = series[k]
                const label = serie.name
                let value = 0
                if (Object.keys(propsTaches).includes(label)) {
                  const total = propsTaches["total"]
                  value = propsTaches[label]
                  value = (value / total) * 100
                }
                serie.data.push(value)
              })

              categories.push(activite_nom)
            }
          })
        }

        acc[index] = { title, series: Object.values(series), categories, colors }
        return acc
      }, {})

      return plotDataByObjectif

    },

    async fetchData() {
      this.fetchProjet(this.projet_id).then((p) => {
        this.projet = p;
      });

      this.fetchObjectifsProjet(this.projet_id).then((o) => {
        this.objectifs = o;
      });

      this.fetchActivitesProjet(this.projet_id).then((a) => {
        this.activites = a;
      });

      this.fetchTachesProjet(this.projet_id).then((t) => {
        this.taches = t;
      });

      var data = {
        projet_id: this.projet_id,
        type_rapport: this.type_rapport,
      };
      this.fetchCommentaires(data).then((commentaires) => {
        this.commentaires = commentaires;
      })

      return
    },

  },
  created() {

    if (this.type_rapport == "interne") {
      this.interne = true;
    } else if (this.type_rapport == "externe") {
      this.externe = true;
    } else {
      this.$router.push({
        name: "404",
      });
    }

    this.fetchData()
  },

  watch: {
    dataFetched(newValue) {
      if (newValue) {
        this.setVizualisations()
      }
    }
  }
};
</script>


<style>
.piecharts {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.piecharts .pie_div {
  width: 50%;
}

.histograms {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.header_page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
}

.header_footer {
  align-self: start;
}

.serdex_logo {
  width: 100px;
  height: 60px;
}
</style>