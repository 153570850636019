<template>
  <div>
    <h1>Rapport</h1>
    <br />
    <div class="print-hide">
      <p>
        Télécharger(format Pdf):
        <v-btn @click="pdfBtnClick" icon x-large color="red"><v-icon>mdi-file-pdf-box</v-icon></v-btn>
      </p>
    </div>
    <br />
    <div class="col-md-12" style="margin:0 auto">
      <div class="print-page">
        <h2>Ensemble du projet</h2>
        <div class="piecharts">
          <div class="pie_div">
            <GaugeComponent :series="gaugeChartDataEchanciers.series" :title="gaugeChartDataEchanciers.title"
              :colors="gaugeChartDataEchanciers.colors"></GaugeComponent>
          </div>
          <div class="pie_div">
            <GaugeComponent :series="gaugeChartDataAvancementActivites.series"
              :title="gaugeChartDataAvancementActivites.title" :colors="gaugeChartDataAvancementActivites.colors">
            </GaugeComponent>
          </div>
        </div>
        <RapportCommentairesComponent :commentaires="firstSectionCommentaires" type_rapport="interne"
          :projet_id="projet_id" :section="sections[0]"></RapportCommentairesComponent>
        <div>
          <br />
          <h2>Salons et évènements</h2>
          <div>
            <SalonsTableComponent :projet_id="projet_id" :headers="salons_headers"></SalonsTableComponent>
          </div>
          <br />
          <RapportCommentairesComponent :commentaires="secondSectionCommentaires" type_rapport="interne"
            :projet_id="projet_id" :section="sections[1]"></RapportCommentairesComponent>
        </div>
      </div>
      <br />
      <div>
        <div class="histograms">
          <div v-for="(objectif, index) in objectifs" :key="objectif.id" class="chart print-page">
            <h2>{{ objectif.nom_objectif }}</h2>
            <BarComponent :categories="barChartDataEchanciers[index]['categories']"
              :data="barChartDataEchanciers[index].series" :title="barChartDataEchanciers[index].title"
              :colors="barChartDataEchanciers[index].colors"></BarComponent>
            <br />
            <BarComponent :categories="barChartDataAvancement[index]['categories']"
              :data="barChartDataAvancement[index].series" :title="barChartDataAvancement[index].title"
              :colors="barChartDataAvancement[index].colors"></BarComponent>
          </div>
        </div>
        <br>
        <br>
        <RapportCommentairesComponent :commentaires="thirdSectionCommentaires" type_rapport="interne"
          :projet_id="projet_id" :section="sections[2]"></RapportCommentairesComponent>
      </div>
    </div>
    <DialogLoaderComponent ref="loader" v-bind:dialog="loader"></DialogLoaderComponent>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SalonsTableComponent from "../Salons/SalonsTableComponent.vue";
import RapportCommentairesComponent from "./RapportCommentairesComponents.vue";
import DialogLoaderComponent from "../Dialog/DialogLoaderComponent.vue";
import BarComponent from "./BarComponent.vue";
import GaugeComponent from "./GaugeComponent.vue";

export default {
  name: "RapportInterne",
  components: { SalonsTableComponent, RapportCommentairesComponent, DialogLoaderComponent, BarComponent, GaugeComponent },
  data: () => ({
    loader: false
  }),
  props: [
    "projet_id",
    "salons_headers",
    "sections",
    "commentaires",
    "objectifs",
    "dataEcheanciersProjet",
    "dataAvancementProjet",
    "stackedBarDataAvancementPerObjectif",
    "stackedBarDataEcheanciersPerObjectif",
    "barChartDataEchanciers",
    "barChartDataAvancement",
    "gaugeChartDataEchanciers",
    "gaugeChartDataAvancementActivites"
  ],
  methods:
  {
    ...mapActions(["fetchRapportInternePdf"]),
    pdfBtnClick() {
      this.loader = true
      this.fetchRapportInternePdf(this.projet_id).then(response => {
        if (response != null) {
          this.loader = false
          window.open(response)
        }
      })
    }
  },
  computed: {
    firstSectionCommentaires() {
      return this.commentaires
        .reduce((accumulator, c) => {
          var section = this.sections[0];
          if (c.section == section) {
            if (!(section in accumulator)) {
              accumulator[section] = [];
            }
            accumulator.push(c);
          }
          return accumulator;
        }, [])
        .reverse();
    },
    secondSectionCommentaires() {
      return this.commentaires
        .reduce((accumulator, c) => {
          var section = this.sections[1];
          if (c.section == section) {
            if (!(section in accumulator)) {
              accumulator[section] = [];
            }
            accumulator.push(c);
          }
          return accumulator;
        }, [])
        .reverse();
    },
    thirdSectionCommentaires() {
      return this.commentaires
        .reduce((accumulator, c) => {
          var section = this.sections[2];
          if (c.section == section) {
            if (!(section in accumulator)) {
              accumulator[section] = [];
            }
            accumulator.push(c);
          }
          return accumulator;
        }, [])
        .reverse();
    },
  },
};
</script>
