<template>
  <div :style="'width: 100%;height: 300px'">
    <VChart :autoresize="true" :option="option"></VChart>
  </div>
</template>

<script>
import { use } from "echarts/core";
import VChart, { THEME_KEY } from "vue-echarts";
import { SVGRenderer } from "echarts/renderers";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { PieChart } from "echarts/charts";
use([
  SVGRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);
export default {
  name: "GaugeComponent",
  props: ["title", "colors", "series"],
  components: { VChart },
  provide: {
    [THEME_KEY]: "white",
  },
  computed: {
    option() {
      return {
        title: {
          text: this.$props.title,
          left: 'center',
          textStyle:{
            fontSize:14
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          top:20,
        },
        color: this.$props.colors,
        series: this.$props.series
      };
    },
  },
};
</script>