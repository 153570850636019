<template>
    <div :style="'width:90vw ;height: 400px'">
    <!-- <div :style="'width: ' + width + ';height: ' + height"> -->
        <VChart :autoresize="true" :option="option"></VChart>
    </div>
</template>

<script>
import { use } from "echarts/core";
import { GridComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { SVGRenderer } from "echarts/renderers";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
use([
    SVGRenderer,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent
]);
export default {
    name: "BarComponent",
    components: { VChart },
    provide: {
        [THEME_KEY]: "white",
    },
    props: ["title","categories", "data","colors"],
    data: () => ({
        joursEcoules: null,
        joursRestants: null,
        echeancierItemsColors: {
            "Non débuté": "#7191c3",
            "En retard": "#ff0000",
            "En cours": "#ffc000",
            Terminé: "#c5e0b4",
        },
    }),
    computed:
    {
        option() {
            return {
                title: {
                    show:true,
                    text: this.$props.title,
                    left:25,
                    textVericalAlign:"top",
                    textStyle: {
                        fontSize:12,
                    },
                },
                legend: {
                    show: true,
                    top: 30
                },
                color:this.$props.colors,
                tooltip:
                {
                    formatter: (params) => {
                        let seriesName = params.seriesName
                        let value = params.value
                        return seriesName + '<br/>' + value + '<br/>'
                    }
                },
                xAxis: {
                    type: 'value',
                    min: 0,
                },
                yAxis: {
                    type: 'category',
                    data:this.$props.categories,
                    show: true,
                    axisLabel:{
                        fontSize:9,
                        width:300,
                        lineHeight:12,
                        fontWeight:'bold',
                        overflow:'break'
                    }
                },
                grid:{
                    left:'45%',
                },
                series: this.$props.data
            }
        },
    },
}
</script>